(function() {
    var MenuBar = function(element) {
        this.element = element;
        this.items = getChildrenByClassName(this.element, 'menu-bar__item');
        this.mobHideItems = this.element.getElementsByClassName('menu-bar__item--hide');
        this.moreItemsTrigger = this.element.getElementsByClassName('js-menu-bar__trigger');
        initMenuBar(this);
    };

    function initMenuBar(menu) {
        setMenuTabIndex(menu); // set correct tabindexes for menu item
        initMenuBarMarkup(menu); // create additional markup
        checkMenuLayout(menu); // set menu layout
        menu.element.classList.add('menu-bar--loaded'); // reveal menu

        // custom event emitted when window is resized
        menu.element.addEventListener('update-menu-bar', function(event){
            checkMenuLayout(menu);
            if(menu.menuInstance) menu.menuInstance.toggleMenu(false, false); // close dropdown
        });

        // keyboard events
        // open dropdown when pressing Enter on trigger element
        if(menu.moreItemsTrigger.length > 0) {
            menu.moreItemsTrigger[0].addEventListener('keydown', function(event) {
                if( (event.keyCode && event.keyCode == 13) || (event.key && event.key.toLowerCase() == 'enter') ) {
                    if(!menu.menuInstance) return;
                    menu.menuInstance.selectedTrigger = menu.moreItemsTrigger[0];
                    menu.menuInstance.toggleMenu(!menu.subMenu.classList.contains('menu--is-visible'), true);
                }
            });

            // close dropdown on esc
            menu.subMenu.addEventListener('keydown', function(event) {
                if((event.keyCode && event.keyCode == 27) || (event.key && event.key.toLowerCase() == 'escape')) { // close submenu on esc
                    if(menu.menuInstance) menu.menuInstance.toggleMenu(false, true);
                }
            });
        }

        // navigate menu items using left/right arrows
        menu.element.addEventListener('keydown', function(event) {
            if( (event.keyCode && event.keyCode == 39) || (event.key && event.key.toLowerCase() == 'arrowright') ) {
                navigateItems(menu.items, event, 'next');
            } else if( (event.keyCode && event.keyCode == 37) || (event.key && event.key.toLowerCase() == 'arrowleft') ) {
                navigateItems(menu.items, event, 'prev');
            }
        });
    };

    function setMenuTabIndex(menu) { // set tabindexes for the menu items to allow keyboard navigation
        var nextItem = false;
        for(var i = 0; i < menu.items.length; i++ ) {
            if(i == 0 || nextItem) menu.items[i].setAttribute('tabindex', '0');
            else menu.items[i].setAttribute('tabindex', '-1');
            if(i == 0 && menu.moreItemsTrigger.length > 0) nextItem = true;
            else nextItem = false;
        }
    };

    function initMenuBarMarkup(menu) {
        if(menu.mobHideItems.length == 0 ) { // no items to hide on mobile - remove trigger
            if(menu.moreItemsTrigger.length > 0) menu.element.removeChild(menu.moreItemsTrigger[0]);
            return;
        }

        if(menu.moreItemsTrigger.length == 0) return;

        // create the markup for the Menu element
        var content = '';
        menu.menuControlId = 'submenu-bar-'+Date.now();
        for(var i = 0; i < menu.mobHideItems.length; i++) {
            var item = menu.mobHideItems[i].cloneNode(true),
                svg = item.getElementsByTagName('svg')[0],
                label = item.getElementsByClassName('menu-bar__label')[0];

            svg.setAttribute('class', 'icon menu__icon');
            content = content + '<li role="menuitem"><span class="menu__content js-menu__content">'+svg.outerHTML+'<span>'+label.innerHTML+'</span></span></li>';
        }

        menu.moreItemsTrigger[0].setAttribute('role', 'button');
        menu.moreItemsTrigger[0].setAttribute('aria-expanded', 'false');
        menu.moreItemsTrigger[0].setAttribute('aria-controls', menu.menuControlId);
        menu.moreItemsTrigger[0].setAttribute('aria-haspopup', 'true');

        var subMenu = document.createElement('menu'),
            customClass = menu.element.getAttribute('data-menu-class');
        subMenu.setAttribute('id', menu.menuControlId);
        subMenu.setAttribute('class', 'menu js-menu '+customClass);
        subMenu.innerHTML = content;
        document.body.appendChild(subMenu);

        menu.subMenu = subMenu;
        menu.subItems = subMenu.getElementsByTagName('li');

        menu.menuInstance = new Menu(menu.subMenu); // this will handle the dropdown behaviour
    };

    function checkMenuLayout(menu) { // switch from compressed to expanded layout and viceversa
        var layout = getComputedStyle(menu.element, ':before').getPropertyValue('content').replace(/\'|"/g, '');
        menu.element.classList.toggle('menu-bar--collapsed', layout == 'collapsed');
    };

    function navigateItems(list, event, direction, prevIndex) { // keyboard navigation among menu items
        event.preventDefault();
        var index = (typeof prevIndex !== 'undefined') ? prevIndex : Array.prototype.indexOf.call(list, event.target),
            nextIndex = direction == 'next' ? index + 1 : index - 1;
        if(nextIndex < 0) nextIndex = list.length - 1;
        if(nextIndex > list.length - 1) nextIndex = 0;
        // check if element is visible before moving focus
        (list[nextIndex].offsetParent === null) ? navigateItems(list, event, direction, nextIndex) : moveFocusFn(list[nextIndex]);
    };

    function checkMenuClick(menu, target) { // close dropdown when clicking outside the menu element
        if(menu.menuInstance && !menu.moreItemsTrigger[0].contains(target) && !menu.subMenu.contains(target)) menu.menuInstance.toggleMenu(false, false);
    };

    function getChildrenByClassName(el, className) {
        var children = el.children,
            childrenByClass = [];
        for (var i = 0; i < children.length; i++) {
            if (children[i].classList.contains(className)) childrenByClass.push(children[i]);
        }
        return childrenByClass;
    };

    function moveFocusFn(element) {
        element.focus();
        if (document.activeElement !== element) {
            element.setAttribute('tabindex','-1');
            element.focus();
        }
    };

    // init MenuBars objects
    var menuBars = document.getElementsByClassName('js-menu-bar');
    if( menuBars.length > 0 ) {
        var j = 0,
            menuBarArray = [];
        for( var i = 0; i < menuBars.length; i++) {
            var beforeContent = getComputedStyle(menuBars[i], ':before').getPropertyValue('content');
            if(beforeContent && beforeContent !='' && beforeContent !='none') {
                (function(i){menuBarArray.push(new MenuBar(menuBars[i]));})(i);
                j = j + 1;
            }
        }

        if(j > 0) {
            var resizingId = false,
                customEvent = new CustomEvent('update-menu-bar');
            // update Menu Bar layout on resize
            window.addEventListener('resize', function(event){
                clearTimeout(resizingId);
                resizingId = setTimeout(doneResizing, 150);
            });

            // close menu when clicking outside it
            window.addEventListener('click', function(event){
                menuBarArray.forEach(function(element){
                    checkMenuClick(element, event.target);
                });
            });

            function doneResizing() {
                for( var i = 0; i < menuBars.length; i++) {
                    (function(i){menuBars[i].dispatchEvent(customEvent)})(i);
                };
            };
        }
    }
}());